import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";
import ActionBar from "../components/OldActionBar";
import Button from "../components/buttons/Button";
import { navigate } from "gatsby-link";
import PageWrapper from "../components/PageWrapper";
import queryString from "query-string";
import { MUTED_TEXT } from "../colors";
import DarkPageHeading from "../components/DarkPageHeading";

const GET_ROUND_TEMPLATE = gql`
  query GetRoundTemplate($id: ID!) {
    roundTemplate: getRoundTemplate(id: $id) {
      id
      title
      questionTemplates {
        id
        number
        text
        answer
        facts
      }
    }
  }
`;

const ADD_ROUND_TO_QUIZ_FROM_TEMPLATE = gql`
  mutation AddRoundToQuizFromTemplate($quizId: ID!, $roundTemplateId: ID!) {
    addRoundToQuizFromTemplate(
      quizId: $quizId
      roundTemplateId: $roundTemplateId
    ) {
      quiz {
        id
        name
        publicId
      }
    }
  }
`;

const QuestionTemplatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  font-size: 20px;
`;

const QuestionTemplate = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const QuestionNumber = styled.div`
  flex: 0;
  padding-right: 10px;
`;

const QuestionDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  margin-bottom: 10px;
`;

const AnswerLabel = styled.span`
  color: rgb(153, 153, 153);
`;

const Answer = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const Facts = styled.div`
  font-size: 18px;
  color: ${MUTED_TEXT};
  line-height: 1.3em;

  > p {
    margin: 0;
  }
`;

export default function QuizList() {
  const { quizId, roundTemplateId, eventId } = queryString.parse(
    location.search
  );

  const { data } = useQuery(GET_ROUND_TEMPLATE, {
    variables: {
      id: roundTemplateId,
    },
  });

  const [addRoundToQuizFromTemplate] = useMutation(
    ADD_ROUND_TO_QUIZ_FROM_TEMPLATE
  );

  if (!data) {
    return null;
  }

  const roundTemplate = data.roundTemplate;
  const title = roundTemplate.title;
  const questionTemplates = roundTemplate.questionTemplates;

  const handleAddToQuiz = async () => {
    await addRoundToQuizFromTemplate({
      variables: {
        quizId,
        roundTemplateId,
      },
    });
    if (eventId) {
      await navigate(`/create-edit-event?eventId=${eventId}`);
    } else {
      await navigate(`/create-edit-quiz?quizId=${quizId}`);
    }
  };

  const handleCancel = async () => {
    await navigate(
      `/select-round-template?quizId=${quizId}&eventId=${eventId}`
    );
  };

  return (
    <PageWrapper>
      <DarkPageHeading>Round: {title}</DarkPageHeading>
      <QuestionTemplatesContainer>
        {questionTemplates.map((qt) => (
          <QuestionTemplate key={qt.id}>
            <QuestionNumber>{qt.number}.</QuestionNumber>
            <QuestionDetails>
              <Text>{qt.text}</Text>
              <Answer>
                <AnswerLabel>Answer: </AnswerLabel>
                {qt.answer}
              </Answer>
              {qt.facts && (
                <Facts dangerouslySetInnerHTML={{ __html: qt.facts }} />
              )}
            </QuestionDetails>
          </QuestionTemplate>
        ))}
      </QuestionTemplatesContainer>
      <ActionBar>
        <Button size="large" onClick={handleAddToQuiz}>
          Add to {eventId ? "Event" : "Quiz"}
        </Button>
        <Button size="large" onClick={handleCancel}>
          Cancel
        </Button>
      </ActionBar>
    </PageWrapper>
  );
}
